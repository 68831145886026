/* You can add global styles to this file, and also import other style files */
@import "bootstrap/bootstrap";
@import "spinner";
@import "utils";
@import "rtl";
@import "responsive";
// @import '~@swimlane/ngx-datatable/index.css';
// @import '~@swimlane/ngx-datatable/themes/material.scss';
// @import '~@swimlane/ngx-datatable/assets/icons.css';
@media print {
    .breadcrumb {
        display: none !important;
    }
}
