/* CSS puro generado desde SCSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
    font-family: Roboto, sans-serif;
    color:var(--color-text-primary);
    background-color: var(--color-secondary) !important;
    overflow-y: scroll;
  }

  .resource-table-cell {
    width: 100%;
  }

.resource-table-row {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

  
  ul.manage-tabs > li.nav-item > a {
    color: var(--color-text-primary) !important;
  }
  
  ul.manage-tabs > li.nav-item > a.active {
    color: var(--color-alt2)!important;
    background-color: var(--color-primary);
  }
  
  .btn-danger {
    background-color: var(--color-danger) !important;
    border-color: var(--color-danger) !important;
  }

  .btn-danger:hover {
    background-color: var(--color-danger-darker1) !important;
    border-color: var(--color-danger-darker1) !important;
  }

  .btn-alt {
    background-color: var(--color-alt2) !important;
    border-color: var(--color-alt2) !important;
    color: white !important;
  }

  .btn-alt:hover {
    background-color: var(--color-alt2-darker) !important;
    border-color: var(--color-alt2-daker) !important;
  }

  .btn-primary {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
    color: var(--color-text-primary) !important;
  }
  
  .btn-primary:hover {
    background-color: var(--color-primary-darker1) !important;
    border-color: var(--color-primary-darker1) !important;
    
  }
  
  .validation-error {
    color: #d21d3b;
    font-size: 80%;
    padding-left: 5px;
    padding-top: 2px;
  }
  
  .white-link {
    color: white !important;
  }
  
  .unread {
    font-weight: bold !important
  }
  
  .datatable-body-row.active .datatable-row-group {
    background-color: white !important;
  }
  
  .tag {
    font-size: 14px;
    padding: 0.3em 0.4em 0.4em;
    margin: 0 0.1em;
  }
  
  .tag a {
    color: #bbb;
    cursor: pointer;
    opacity: 0.6;
  }
  
  .tag a:hover {
    opacity: 1.0;
  }
  
  .tag .remove {
    vertical-align: bottom;
    top: 0;
  }
  
  .tag a {
    margin: 0 0 0 0.3em;
  }
  
  .tag a .glyphicon-white {
    color: #fff;
    margin-bottom: 2px;
  }
  
  .social-buttons {
    position: absolute;
    bottom: 0;
    margin-left: 1.15rem;
  }
  
  .social {
    text-align: center;
    padding: 0.35rem;
    font-size: 1.2rem;
    width: 2rem;
    border-radius: 50%;
    text-decoration: none;
    margin: 0.5rem;
    height: 2rem;
    vertical-align: middle;
  }
  
  .social:hover {
    opacity: 0.7;
  }
  
  .fa-instagram {
    background: #8a3ab9;
    color: white;
  }
  
  .fa-youtube {
    background: #bb0000;
    color: white;
  }
  
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
  
  .twitter-icon img {
    width: 2rem;      
    height: 2rem;
    border-radius: 50%;  
    background-color: white;  
    margin: 0.5rem;
}

  dl {
    display: grid;
    grid-template-columns: max-content auto;
  }
  
  dd {
    margin-left: 1.5rem !important;
  }
  
  .login-button {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
  }

  .help-block.with-errors {
    color: #ff5050;
    margin-top: 5px;
  
  }

  .error-message {
    color:#a94442; font-size:80%; padding-left: 5px; padding-top: 2px
}

.login-text {
  color:var(--color-primary) !important;
}

.login-img {
  display: block;
  max-width:15rem;
  max-height:15rem;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.header-link {
  font-size: 0.95rem;
  margin-left: 1.5rem;
  color:var(--color-text-primary) !important
  
}

.header-link:hover {
  color:var(--color-alt2) !important;
  text-decoration: none;
}

.header-link-group {
  margin-left: 3rem;
}

.header-logo {
  max-width:3rem;
  max-height:3rem;
  display: inline;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.header-logo-text {
  margin-left: 0.5rem;
  font-size: 1.2rem;
}

.header-title-link {
  color: var(--color-text-primary);
}
.header-title-link:hover {
  color:var(--color-alt2) !important;
  text-decoration: none;
}


.attributes {
  margin-top: 20px;
}

.attribute {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.attribute-name {
  flex: 1;
  text-align: left;
  margin-right: 10px;
  font-weight: bold;
}

.attribute-value {
  flex: 2;
  text-align: left;
}

.msg-attribute {

  margin-bottom: 0.5rem;
}

.msg-attribute-name {

  margin-right: 0.5rem;
  font-weight: bold;
}

.msg-attribute-value {
  flex: 2;
  text-align: left;
}


.my-panel {
  margin: 20px auto !important;
  padding: 20px;
  background-color: #fffcfc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.small-button {
  font-size: 13px !important;
}

.auxNavbar {
	margin-top: 0;
	width: 100%;
	height: 100%;
	max-width: 1200px;
  
}


.auxNavbar > li > a.active {
  color: var(--color-alt2)!important;
  background-color: #fffcfc;;
}

.auxNavbar li a {
	text-align: center;
	display: flex;
	justify-content: space-around;
	flex-direction: column;
	transition: color 0.2s;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fffcfc;
  color: var(--color-text-primary);
  padding: 5px;
}

.header-link-active {
  color: var(--color-alt2) !important;
  text-decoration: underline;
}

.modal-backdrop {
  /* bug fix - no overlay */    
  display: none;    
}

.link-style {
  cursor: pointer !important;
  color: var(--color-link) !important; /* Puedes cambiar el color a tu elección */
}

.link-style:hover {
  color: var(--color-link-hover) !important;
}

.action-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Esto permitirá que los elementos se envuelvan si no hay espacio suficiente */
}

#dialogContainer {
  display: none;
}