@media screen and (max-width: 992px) {
    .push-right {
        .sidebar {
            left: 235px !important;
        }
    }
}


@media screen and (min-width: 993px) {
    .sidebar {
        display: none !important;
    }
}