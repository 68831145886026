
.sidebar {
  border-radius: 0;
  position: fixed;
  z-index: 1000;
  top: 56px;
  left: 235px;
  width: 235px;
  margin-left: -235px;
 /* margin-bottom: 48px;
  */
  border: none;
  border-radius: 0;
  overflow-y: auto;
  background-color: var(--color-primary);
  bottom: 0;
  overflow-x: hidden;
  padding-bottom: 40px;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.sidebar .list-group a.list-group-item {
  background: var(--color-primary);
  border: 0;
  border-radius: 0;
  color: var(--color-text-primary);
  text-decoration: none;
}
.sidebar .list-group a.list-group-item .fa {
  margin-right: 10px;
}
.sidebar .list-group a:hover {
  background: var(--color-primary-darker1);
  color: #fff;
}
.sidebar .list-group a.router-link-active {
  background: var(--color-primary-darker1);
  color: #fff;
}
.sidebar .list-group .header-fields {
  padding-top: 10px;
}
.sidebar .list-group .header-fields > .list-group-item:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .sidebar-dropdown *:focus {
  border-radius: none;
  border: none;
}
.sidebar .sidebar-dropdown .panel-title {
  font-size: 1rem;
  height: 50px;
  margin-bottom: 0;
}
.sidebar .sidebar-dropdown .panel-title a {
  color: var(--color-text-primary);
  text-decoration: none;
  font-weight: 400;
  background: var(--color-primary);
}
.sidebar .sidebar-dropdown .panel-title a span {
  position: relative;
  display: block;
  padding: 0.75rem 1.5rem;
  padding-top: 1rem;
}
.sidebar .sidebar-dropdown .panel-title a:hover, .sidebar .sidebar-dropdown .panel-title a:focus {
  color: #fff;
  outline: none;
  outline-offset: -2px;
}
.sidebar .sidebar-dropdown .panel-title:hover {
  background: var(--color-primary-darker1);
}
.sidebar .sidebar-dropdown .panel-collapse {
  border-radius: 0;
  border: none;
}
.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item {
  border-radius: 0;
  background-color: var(--color-primary);
  border: 0 solid transparent;
}
.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item a {
  color: var(--color-text-primary);
}
.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item a:hover {
  color: #fff;
}
.sidebar .sidebar-dropdown .panel-collapse .panel-body .list-group-item:hover {
  background: var(--color-primary-darker1);
}
.nested-menu .list-group-item {
  cursor: pointer;
}
.nested-menu .nested {
  list-style-type: none;
}
.nested-menu ul.submenu {
  display: none;
  height: 0;
}
.nested-menu .expand ul.submenu {
  display: block;
  list-style-type: none;
  height: auto;
}
.nested-menu .expand ul.submenu li a {
  color: var(--color-text-primary);
  padding: 10px;
  display: block;
}
@media screen and (max-width: 992px) {
  .sidebar {
    top: 54px;
    left: 0px;
 }
}
@media print {
  .sidebar {
    display: none !important;
 }
}
@media (min-width: 992px) {
  .header-fields {
    display: none;
 }
}
/* ::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(255, 255, 255, 1);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 3px rgba(255, 255, 255, 1);
} */
.toggle-button {
  position: fixed;
  width: 235px;
  cursor: pointer;
  padding: 12px;
  bottom: 0;
  color: #fff;
  background: var(--color-primary);
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.toggle-button i {
  font-size: 23px;
}
.toggle-button:hover {
  background: var(--color-primary-darker1);
  color: #fff;
}
.collapsed {
  width: 50px;
}
.collapsed span {
  display: none;
}
.dot {
  height: 25px;
  background-color: #b50c00;
  color: #fff;
  font-size: 15px;
  text-align: center;
  border-radius: 12.5px;
  display: inline-block;
}
.circle-badge {
  display: inline-table;
  vertical-align: middle;
  width: 1.35rem;
  height: 1rem;
  margin-left: 0.4rem;
  background-color: var(--color-alt1);
  border-radius: 50%;
  font-size: 0.85rem;
  font-weight: bold;
}
.circle-badge-content {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
