.navbar {
    background-color: var(--color-primary)
}
.navbar .navbar-brand {
    color: #fff;
}
.navbar .nav-item > a {
    color: #fff;
}
.navbar .nav-item > a:hover {
    color: #fff;
}
.messages {
    width: 300px;
}
.messages .media {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
}
.messages .media:last-child {
    border-bottom: none;
}
.messages .media-body h5 {
    font-size: 13px;
    font-weight: 600;
}
.messages .media-body .small {
    margin: 0;
}
.messages .media-body .last {
    font-size: 12px;
    margin: 0;
}
.dropdown-menu-right {
    left: -50px !important;
}
/*.user-bar {
    border-radius: 0;
    position: fixed;
    z-index: 1000;
    top: 55.97px;
    right: 0px;
    left: 235px;
    height: 70px;
    margin-left: -235px;
    margin-bottom: 48px;
    border: none;
    border-radius: 0;
    overflow-y: auto;
    background-color: $topnav-background-color;
    overflow-x: hidden;
    padding-bottom: 40px;
    white-space: nowrap;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    // border-top: 1px solid rgba(255,255,255,0.3);
}
*/
.user-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
}
