:root {
    --color-primary: #FFCC70;
    --color-primary-darker1: #cc962f;
    --color-secondary: #FFFADD;
    --color-secondary-darker1: #fdf6ca;
    --color-danger: #dc3545;
    --color-danger-darker1: #af3541;
    --color-text-primary: #333232;
    --color-link: #007bff;
    --color-link-hover: #0056b3;
  }

